import React from 'react';
import PageLayout from 'components/layout/PageLayout';
import useScript from 'react-script-hook';
import { cookiebotScriptUrl } from 'config/settings';
import { AppContextProvider } from 'contexts/AppContext';
import { I18nProvider } from 'i18n';

export default function CookieAgreement() {
  useScript({
    src: cookiebotScriptUrl,
  });

  return (
    <AppContextProvider>
      <I18nProvider>
        <PageLayout
          i18nTitle="cookie_declaration.title"
          i18nMetaTitle="cookie_declaration.meta_title"
          i18nMetaDescription="cookie_declaration.meta_description"
        >
          <div id="CookieDeclaration" />
        </PageLayout>
      </I18nProvider>
    </AppContextProvider>
  );
}
